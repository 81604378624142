import {motion, AnimatePresence} from "framer-motion";
import {useEffect, useRef} from "react";

const Rule = ({showRule, setShowRule}) => {
  const modalRef = useRef(null);

  return (
    <AnimatePresence>
      {showRule && (
        <motion.div
          initial={{opacity: 0}}
          animate={{opacity: 1}}
          exit={{opacity: 0}}
          className="fixed z-10 inset-0 overflow-y-hidden"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >

          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              className="hidden sm:inline-block sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <motion.div
              ref={modalRef}
              initial={{scale: 0.9, opacity: 0}}
              animate={{scale: 1, opacity: 1}}
              exit={{scale: 0.9, opacity: 0}}
              transition={{type: "spring", damping: 20, stiffness: 300}}
              className="h-[86vh] md:h-[90vh] w-full md:w-[90%] inline-block align-bottom bg-white rounded-lg px-4 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:p-6"
            >
              <div className="h-full">
                <div className="h-[93%] overflow-y-auto py-5 mb-3">


                  <div className="modal-content">
                    <h2 className="text-xl font-bold mb-4">Game Rules</h2>
                    <ol className="list-decimal pl-5">
                      <li className="mb-3">
                        <span className="font-semibold text-lg">Number of Questions:</span>
                        <p>Each practice session will consist of <strong>24 questions</strong>.</p>
                      </li>
                      <li className="mb-3">
                        <span className="font-semibold text-lg">Scoring:</span>
                        <p>Each correct answer will earn <strong>1 point</strong>.</p>
                        <p>After submitting, players can choose to <strong>review</strong> the results or proceed to the <strong>next question</strong>.</p>
                        <p>If players select <strong>review</strong> and answer correctly, <strong>no additional points</strong> will be awarded for that question.</p>
                      </li>
                      <li className="mb-3">
                        <span className="font-semibold text-lg">Pronunciation Hints:</span>
                        <p>Players have <strong>3 chances</strong> to listen to the pronunciation for each question.</p>
                        <p>These chances will <strong>reset</strong> when moving to a new question.</p>
                      </li>
                      <li className="mb-3">
                        <span className="font-semibold text-lg">Help Hints:</span>
                        <p>Players have <strong>3 help hints</strong> for each question.</p>
                        <p>Each help hint will automatically <strong>supply a letter</strong> in one of the empty slots of the answer.</p>
                        <p>Help hints will <strong>reset</strong> when moving to a new question.</p>
                      </li>
                      <li className="mb-3">
                        <span className="font-semibold text-lg">Gameplay:</span>
                        <p>Each question requires players to <strong>arrange the given letters</strong> into a complete word or phrase.</p>
                        <p>The game will <strong>display the letters needed</strong> and indicate the <strong>number of slots</strong> to fill.</p>
                        <p>If the answer is a <strong>phrase</strong> (consisting of multiple words), the positions of <strong>spaces</strong> will be pre-defined to guide players on where to place the letters.</p>
                      </li>
                      <li className="mb-3">
                        <span className="font-semibold text-lg">Additional Hints:</span>
                        <p>Each question will provide a hint about the <strong>meaning of the word</strong> in English.</p>
                        <p>There will also be an <strong>example sentence</strong> in English to help players understand the <strong>context</strong> in which the word/phrase is used.</p>
                      </li>
                      <li className="mb-3">
                        <span className="font-semibold text-lg">Results After Each Question:</span>
                        <p>After completing or skipping a question, the <strong>results will display</strong> whether the answer was correct or incorrect.</p>
                        <p>Players can choose to <strong>review</strong> to attempt again, but <strong>no points will be awarded</strong> for correct answers during the review.</p>
                      </li>
                      <li className="mb-3">
                        <span className="font-semibold text-lg">Final Results:</span>
                        <p>After completing all <strong>24 questions</strong>, players will be directed to a <strong>results page</strong> that shows the total correct answers and the score achieved in that practice session.</p>
                      </li>
                      <li className="mb-3">
                        <span className="font-semibold text-lg">High Score Rankings:</span>
                        <p>The game includes a ranking system for the highest-scoring performances, allowing players to see how they compare with others.</p>
                      </li>
                    </ol>
                  </div>

                </div>
                <div className="text-center">
                  <button onClick={() => {
                    setShowRule(false)
                  }} className="bg-blue-400 hover:bg-blue-600 px-8 py-2 text-white rounded-lg">Got it</button>
                </div>
              </div>
            </motion.div>
          </div>
        </motion.div>)}
    </AnimatePresence>

  )
}

export default Rule
