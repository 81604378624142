import React, {useState, useEffect, useRef} from 'react';
import {FaCheck} from "react-icons/fa";
import {MdOutlineClear, MdOutlinePageview} from "react-icons/md";
import Swal from 'sweetalert2'
import {
  vocabularies,
  meanings,
  meaningVis,
  examples,
  illustrations,
  exs,
  exs_vi
} from "./data"
import {AiFillSound} from "react-icons/ai";
import Rank from "./components/Rank"
import {IoHelp} from "react-icons/io5";
import Navbar from './components/Navbar';
import Rule from './components/Rule';
import EndGame from './components/EndGame';
import axios from 'axios';
import Slide from "./components/Slide"

function App() {
  const [meaing, setMeaing] = useState("")
  const [meaningVi, setMeaningVi] = useState("")
  const [choose, setChoose] = useState([])
  const [answer, setAnswer] = useState([])
  const [result, setResult] = useState([])
  const [scoreTrue, setScoreTrue] = useState(0)
  const [scoreFail, setScoreFail] = useState(0)
  const [example, setExample] = useState("")
  const [ex, setEx] = useState("")
  const [ex_vi, setEx_vi] = useState("")
  const [illustration, setIllustration] = useState("")
  const [currentQuestion, setCurrentQuestion] = useState(1)
  const audioRef = useRef(null)
  const [audioSrc, setAudioSrc] = useState(false)
  const [countListening, setCountListening] = useState(0)
  const [countHelping, setCountHelping] = useState(0)
  const [submitted, setSubmitted] = useState(false)
  const [insertScore, setInsertScore] = useState(false)
  const [showSlide, setShowSlide] = useState(true)

  const maxScore = 24
  const maxListening = 3
  const maxHelping = 3

  const [showRule, setShowRule] = useState(false)

  useEffect(() => {
    if (insertScore) {
      setInsertScore(false)
      const url = process.env.REACT_APP_API + "/addScore.php"
      axios.post(url, {
        fullName: localStorage.getItem("auth"),
        score: scoreTrue
      }, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(function (response) {
        console.log(response);
      }).catch(function (error) {
        console.log(error);
      });
    }
  }, [insertScore])

  useEffect(() => {
    if (localStorage.getItem("scoreTrue") !== null) {
      setScoreTrue(parseInt(localStorage.getItem("scoreTrue")))
    }

    if (localStorage.getItem("scoreFail") !== null) {
      setScoreFail(parseInt(localStorage.getItem("scoreFail")))
    }

    newGame(true)
    setShowRule(true)
  }, [])

  //
  useEffect(() => {
    localStorage.setItem("submitted", submitted)
  }, [submitted])

  useEffect(() => {
    localStorage.setItem("scoreTrue", scoreTrue)
    localStorage.setItem("scoreFail", scoreFail)
  }, [scoreTrue, scoreFail])

  useEffect(() => {
    localStorage.setItem("cur", currentQuestion)
  }, [currentQuestion])

  const onPlayAgain = () => {
    clearScore(false)
  }

  const clearScore = (notify = true) => {
    localStorage.removeItem("scoreTrue")
    localStorage.removeItem("scoreFail")
    localStorage.removeItem("cur")
    setScoreTrue(0)
    setScoreFail(0)
    setCurrentQuestion(1)

    clearVoca()
    newGame()

    if (notify) {
      Swal.fire({
        title: "Clear successfully!",
        icon: "success",
      });
    }
  }

  function addToVoca(value) {
    let array = JSON.parse(localStorage.getItem('voca')) || [];
    array = array.map(Number);

    if (!array.includes(Number(value))) {
      array.push(Number(value));
      localStorage.setItem('voca', JSON.stringify(array));
    }
  }

  function clearVoca() {
    localStorage.removeItem('voca');
  }

  function isInVoca(value) {
    let array = JSON.parse(localStorage.getItem('voca')) || [];
    array = array.map(Number);
    return array.includes(value);
  }

  const newGame = (init = false) => {
    setMeaing("")
    setChoose([])
    setAnswer([])
    setResult([])
    setExample("")
    setEx("")
    setEx_vi("")

    const randS = init && localStorage.getItem("rand") ? parseInt(localStorage.getItem("rand")) : false
    var rand
    if (randS) {
      rand = randS
    } else {
      while (true) {
        const randT = Math.floor(Math.random() * vocabularies.length)
        const exist = isInVoca(randT)
        if (!exist) {
          rand = randT
          addToVoca(rand)
          break
        }
      }
    }
    localStorage.setItem("rand", rand)
    const vocas = vocabularies[rand]

    if (init && localStorage.getItem("listen")) setCountListening(parseInt(localStorage.getItem("listen")))
    else {
      setCountListening(0)
      localStorage.removeItem("listen")
    }

    if (init && localStorage.getItem("help")) setCountHelping(parseInt(localStorage.getItem("help")))
    else {
      setCountHelping(0)
      localStorage.removeItem("help")
    }

    if (init && localStorage.getItem("cur")) setCurrentQuestion(parseInt(localStorage.getItem("cur")))

    if (init && localStorage.getItem("submitted") && localStorage.getItem("submitted") == 'true') setSubmitted(true)


    setChoose(shuffleArray([...vocas]))
    setAnswer([...vocas].map((item) => (item === " " ? " " : "")))
    setResult([...vocas])
    setMeaing(meanings[rand])
    setMeaningVi(meaningVis[rand])
    setExample(examples[rand])
    setIllustration(illustrations[rand] ?? "")
    setEx(exs[rand])
    setEx_vi(exs_vi[rand])
    setAudioSrc(`/audio/${vocas.toString().replaceAll(' ', '_').replaceAll('%20', '_').replaceAll('-', '_')}.mp3`)
  }

  const chooseWord = (e, index) => {
    setAnswer(answer.map((item, i) => item === "" && answer.indexOf("") === i ? choose[index] : item));
    setChoose((prevItems) => prevItems.filter((_, i) => i !== index));
  }

  const unChooseWord = (e, index) => {
    if (!answer[index]) return;

    setChoose([...choose, answer[index]]);
    setAnswer(answer.map((item, i) => index === i ? "" : item));
  }

  const suggest = () => {
    if (countHelping >= maxHelping) return
    localStorage.setItem("help", countHelping + 1)
    setCountHelping((pre) => pre + 1)

    const id = answer.findIndex(item => item == "");
    const firstWordResult = result[id]

    const existChoose = choose.includes(firstWordResult)

    if (existChoose) {
      setChoose(prevItems => prevItems.filter((item, index) => item !== firstWordResult || prevItems.indexOf(item) !== index)); //remove choose
      setAnswer(answer.map((item, i) => item === "" && answer.indexOf("") === i ? firstWordResult : item)); //add answer
    } else {
      let answerT = answer
      answerT = answerT.map((e, i, arr) => e === firstWordResult && !arr.slice(0, i).includes(firstWordResult) ? "" : e);

      setAnswer(answerT.map((item, i) => item === "" && answer.indexOf("") === i ? firstWordResult : item)); //add answer

    }
  }

  const checkAnswer = () => {
    if (answer.toString() == result.toString()) {
      submitted == false && setScoreTrue((pre) => pre + 1)
      Swal.fire({
        title: "Good job!",
        text: "You answered very correctly",
        icon: "success",
        confirmButtonText: "Next",
      }).then((result) => {
        if (result.isConfirmed) {
          setSubmitted(false)
          setCurrentQuestion((pre) => pre + 1)
          newGame()
        }
      });
    } else {
      setScoreFail((pre) => pre + 1)
      Swal.fire({
        title: "Sorry!",
        html: `Sorry, the correct result is <span class="text-red-400 font-bold">${result.join("")}</span>`,
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Next",
        cancelButtonText: "Learn again"

      }).then((result) => {
        if (result.isConfirmed) {
          setSubmitted(false)
          setCurrentQuestion((pre) => pre + 1)
          newGame()
        } else if (result.isDismissed) {
          setSubmitted(true)
        }
      });
    }

    if (currentQuestion == maxScore) {
      clearVoca()
      setInsertScore(true)
    }
  }

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1)); // Chọn một chỉ số ngẫu nhiên từ 0 đến i
      [array[i], array[j]] = [array[j], array[i]]; // Hoán đổi phần tử tại i và j
    }
    return array;
  }

  const getId = (index) => {
    if (result) {
      let count = 1
      for (let i = 0; i < index + 1; i++) {
        if (result[i] == " ") count++
      }

      return count
    }

    return false
  }

  const getAmoutLetters = (index) => {
    if (result) {
      let count = 0
      while (result[index] != " " && index >= 0) {
        index--
        count++
      }
      return count
    }
    return false
  }

  const playAudio = () => {
    if (!audioRef.current.paused) {
      return
    }
    if (countListening >= maxListening) return
    localStorage.setItem("listen", countListening + 1)
    setCountListening((pre) => pre + 1)
    audioRef.current.play();
  }

  return (
    <div>
      {showSlide ? <div>
        <Slide setShowSlide={setShowSlide} />
      </div> : (
        currentQuestion > maxScore ? <EndGame score={scoreTrue} onPlayAgain={onPlayAgain} /> : (
          <>
            <Navbar />

            <div>
              <div className="flex flex-col md:flex-row">

                <div className="basis-full md:basis-8/12">
                  <div className="container mx-auto px-5 mt-5">

                    <div className="flex gap-5">
                      <div className="basis-4/12 md:basis-2/12">
                        <img src={illustration} alt="logo" className="w-50 h-32 mb-4" /></div>

                      <div className="basis-8/12 md:basis-10/12">
                        <p className="mb-2 font-bold">️<span className="text-2xl">️🏅</span> Score: <span className="text-green-400">{scoreTrue}/{maxScore}</span></p>
                        <p className="mb-2 font-bold"> <span className="text-2xl">🍍</span> Meaning: <span className="text-red-400">{meaing}</span> {/*<span className="font-normal">({meaningVi})</span>*/}</p>
                      </div>

                    </div>

                    <p className="mb-4">
                      <span className="text-2xl">💡</span> <span className="font-bold">Example {currentQuestion}:</span> <span className="italic">{ex}</span>
                      {/* <br/><span className="font-bold"><span className="text-2xl">🐥</span> Vietnamese meaning: </span><span> {ex_vi}</span> */}
                    </p>
                    <div className="text-left content-center">
                      {answer && answer.map((char, index) =>
                        result[index] && result[index] != " " ? (
                          <React.Fragment key={index}>
                            {(index == 0 || result[index - 1] == " ") && <span className="ordinal">{getId(index)}. </span>}
                            <input key={index} type="button" className={`cursor-pointer w-12 h-12 bg-slate-100 mr-2 mb-2 text-black text-xl text-center ${char != "" ? "border-slate-400 border-b-4" : ""}`} value={answer[index] ?? ""} readOnly={true} onClick={(e) => unChooseWord(e, index)} />
                            {(index == result.length - 1 || result[index + 1] == " ") && <>({getAmoutLetters(index)} letters)</>}
                          </React.Fragment>
                        ) : (<br />)
                      )}

                      <div>
                        {audioSrc && <audio ref={audioRef} src={audioSrc} />}
                        <button className="bg-blue-500 px-2 py-2 text-white flex gap-3 items-center disabled:bg-blue-300" onClick={playAudio} disabled={countListening >= maxHelping}>
                          <AiFillSound /> Listen ({countListening}/{maxListening})
                        </button>
                      </div>

                    </div>

                    <div className="text-left mt-4 items-center">
                      <h3 className="font-bold text-lg mb-3">Choose letters to form a complete word</h3>
                      {choose && choose.map((char, index) => char != " " && (
                        <input key={index.toString()} type="button" className="cursor-pointer w-12 h-12 bg-slate-100 mr-2 text-black text-xl text-center mb-2" value={char} onClick={(e) => chooseWord(e, index)} />
                      ))}

                      <div className="mt-4 flex gap-2 md:gap-4 justify-left mb-5">
                        <div className="md:flex gap-0 md:gap-2">
                          <button className="bg-green-500 text-white px-4 py-2 flex gap-3 items-center w-36" onClick={checkAnswer}>
                            <FaCheck />
                            Submit
                          </button>

                          <div className="h-[5px] md:h-0"></div>

                          <button className="bg-yellow-500 text-white px-4 py-2 flex gap-3 items-center disabled:bg-yellow-300 w-36" onClick={(e) => {
                            Swal.fire({
                              title: "Confirm",
                              text: "Do you really need help?",
                              icon: "warning",
                              showCancelButton: true,
                              confirmButtonText: "Help me",
                              cancelButtonText: "Cancel"
                            }).then((result) => {
                              if (result.isConfirmed) {
                                suggest(e)
                              }
                            })
                          }}
                            disabled={countHelping >= maxHelping}
                          >
                            <MdOutlinePageview />
                            Help ({countHelping}/{maxHelping})
                          </button>
                        </div>

                        <div className="md:flex gap-0 md:gap-2">
                          <button className="bg-red-500 text-white px-4 py-2 flex gap-3 items-center w-36" onClick={(e) => {
                            Swal.fire({
                              title: "Confirm",
                              text: "Do you really reset your score?",
                              icon: "warning",
                              showCancelButton: true,
                              confirmButtonText: "Yes",
                              cancelButtonText: "Cancel"
                            }).then((result) => {
                              if (result.isConfirmed) {
                                clearScore(e)
                              }
                            });

                          }}>
                            <MdOutlineClear />
                            Reset
                          </button>

                          <div className="h-[5px] md:h-0"></div>

                          <button className="bg-indigo-500 text-white px-4 py-2 flex gap-3 items-center w-36" onClick={() => {
                            setShowRule(true)
                          }}>
                            <IoHelp /> Rules
                          </button>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                <div className="basis-full md:basis-4/12">
                  <Rank />
                </div>

              </div>
            </div>

            <Rule showRule={showRule} setShowRule={setShowRule} />
            <div className="h-12" />
          </>
        )
      )}
    </div>
  );
}

export default App;
