import {document} from "postcss"
import {useEffect, useRef, useState} from "react"
import bgSrc from "./../bg.mp3"

const Slide = ({setShowSlide}) => {
  const logo = "https://mega.com.vn/media/news/1406_hinh-nen-doraemon-4k56.jpg"
  const images = [
    "https://i.imgur.com/XuUFLSy.png",
    "https://i.imgur.com/7iqnDL4.png"
  ]

  const [step, setStep] = useState(0)
  const [play, setPlay] = useState(false)
  const bgRef = useRef(false)

  useEffect(() => {
    window.document.addEventListener('click', () => {
      bgRef.current && bgRef.current.play()
    })
  }, [])

  const decrementStep = () => {
    if (step == 0) return setPlay(false)
    setStep((pre) => pre - 1)
  }

  const incrementStep = () => {
    if (step + 1 == images.length) return setShowSlide(false)
    setStep((pre) => pre + 1)
  }

  return (
    <div>
      <>
        <audio src={bgSrc} ref={bgRef} />
      </>

      {!play ? (
        <>
          <h1 className="font-bold text-center text-3xl mt-5 text-slate-700">TOP<span className="bg-orange-200 px-2 py-[2px] ml-[5px]">ONE</span></h1>
          <div className="flex justify-center mt-3">
            <img src={logo} className={`w-[90%] md:w-[60%] h-[25vh] md:h-[80vh]`} />
          </div>
          <div className="text-center mt-3 flex gap-3 justify-center">
            <button className="px-8 py-2 bg-blue-400 text-white" onClick={() => {
              setPlay(true)
              bgRef.current.play()
            }}>Start now</button>
          </div>
        </>
      ) : (
        <>
          <h1 className="font-bold text-center text-3xl mt-5 text-slate-700">
            Guide <span className="bg-orange-200 py-1 px-4">{step + 1}</span>
          </h1>
          <div className="flex justify-center mt-3">
            {images.map((value, index) =>
              <img src={value} className={`w-[90%] md:w-[60%] h-[25vh] md:h-[80vh] ${index != step && 'hidden'}`} />
            )}
          </div>
          <div className="text-center mt-3 flex gap-3 justify-center">
            <button className="px-8 py-2 bg-red-400 text-white" onClick={decrementStep}>Back</button>
            {step == images.length - 1 ? (
              <>
                <button className="px-8 py-2 bg-blue-400 text-white flex" onClick={incrementStep}>Play</button>
              </>
            ) : (
              <>
                <button className="px-8 py-2 bg-blue-400 text-white flex" onClick={incrementStep}>Next</button>
              </>
            )}
          </div>
        </>
      )}

    </div>
  )
}


export default Slide
