import React, {useState, useEffect} from "react";
import {FiRotateCw} from "react-icons/fi"; import Confetti from "react-confetti";

const EndGame = ({score, onPlayAgain}) => {
  const [displayScore, setDisplayScore] = useState(0);
  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowConfetti(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (displayScore < score) {
      const timer = setTimeout(() => {
        setDisplayScore(prev => Math.min(prev + Math.ceil(score / 50), score));
      }, 20);

      return () => clearTimeout(timer);
    }
  }, [displayScore, score]);

  const getMessage = () => {
    if (score >= 9) return "Incredible! You're a gaming legend!";
    if (score >= 7) return "Amazing job! You're a true champion!";
    if (score >= 5) return "Great performance! Well done!";
    if (score >= 3) return "Good effort! Keep improving!";
    return "Nice try! Practice makes perfect!";
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-purple-600 to-blue-500 p-4">
      {showConfetti && <Confetti recycle={false} numberOfPieces={200} />}
      <div className="bg-white bg-opacity-90 rounded-xl shadow-2xl p-8 max-w-md w-full text-center relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-tr from-yellow-200 to-pink-200 opacity-50 z-0"></div>
        <div className="relative z-10">
          <h1 className="text-4xl font-bold mb-4 text-gray-800">Game Over</h1>
          <div className="text-6xl font-extrabold mb-6 text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600">
            {displayScore}
          </div>
          <p className="text-xl mb-8 text-gray-700">{getMessage()}</p>
          <button
            onClick={onPlayAgain}
            className="bg-gradient-to-r from-green-400 to-blue-500 text-white font-bold py-3 px-6 rounded-full transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            aria-label="Play Again"
          >
            <span className="flex items-center justify-center">
              <FiRotateCw className="mr-2" />
              Play Again
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default EndGame;
