import axios from "axios";
import React, {useEffect, useState} from "react";
import {FaSort, FaSortUp, FaSortDown} from "react-icons/fa";

const Rank = () => {
  const [sampleData, setSampleData] = useState([])
  useEffect(() => {
    const url = process.env.REACT_APP_API + "/getRank.php"
    axios.get(url, {}).then(function (response) {
      console.log(response);
      setSampleData(response.data)
    }).catch(function (error) {
      console.log(error);
    });

  }, [])

  return (
    <div className="container mx-auto mt-5 px-5">
      <h1 className="text-3xl font-bold mb-3 text-center">Rankings</h1>
      <div className="overflow-x-auto bg-white shadow">
        <table className="w-full table-auto" role="grid" aria-label="Ranking">
          <thead>
            <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
              <th className="py-3 px-6 text-left cursor-pointer">
                #
              </th>
              <th className="py-3 px-6 text-left cursor-pointer">
                Fullname
              </th>
              <th className="py-3 px-6 text-left cursor-pointer">
                Score
              </th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-sm font-light">
            {sampleData.map((item) => (
              <tr
                key={item.id}
                className="border-b border-gray-200 hover:bg-gray-100 focus:outline-none focus:bg-gray-300 transition duration-150"
                tabIndex="0"
              >
                <td className="py-3 px-6 text-left whitespace-nowrap">{item.id}</td>
                <td className="py-3 px-6 text-left">{item.fullname}</td>
                <td className="py-3 px-6 text-left">{item.score}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Rank
