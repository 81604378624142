import React, {useState, useEffect, useRef} from "react";
import {FaUser} from "react-icons/fa";
import {motion, AnimatePresence} from "framer-motion";
import avatarSrc from "../avatar.png"
import {CgLogOut} from "react-icons/cg";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [fullName, setFullName] = useState("");
  const [error, setError] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    if (localStorage.getItem("auth")) {
      setIsLoggedIn(true)
      setIsOpen(false)
      setFullName(localStorage.getItem("auth"))
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (fullName.trim() === "") {
      setError("Full name is required");
      return;
    }
    setIsLoggedIn(true);
    setIsOpen(false);
    setError("");
    localStorage.setItem("auth", fullName.trim())
    announceToScreenReader(`Welcome, ${fullName}! You have successfully logged in.`);
  };

  const announceToScreenReader = (message) => {
    const announcement = document.createElement("div");
    announcement.setAttribute("aria-live", "polite");
    announcement.setAttribute("aria-atomic", "true");
    announcement.classList.add("sr-only");
    announcement.textContent = message;
    document.body.appendChild(announcement);
    setTimeout(() => document.body.removeChild(announcement), 1000);
  }

  const logout = () => {
    setIsLoggedIn(false)
    setFullName("")
    setIsOpen(true)
    localStorage.removeItem("auth")
  }

  return (
    <>
      <nav className="bg-orange-100 mb-3">
        <div className="px-5">
          <div className="flex justify-between h-16">
            <div className="flex-shrink-0 flex items-center">
              <h1 className="text-2xl font-bold">TOPONE</h1>
            </div>
            <div className="hidden sm:ml-6 sm:flex sm:items-center">
              {isLoggedIn ? (
                <div className="ml-3 relative">
                  <div className="flex items-center">
                    <span className="text-gray-700 font-medium">
                      Hi <span className="text-red-400 font-bold">{fullName}</span>
                    </span>
                    <img
                      className="size-14 rounded-full"
                      src={avatarSrc}
                      alt="User avatar"
                    />
                    <button className="font-bold bg-slate-400 text-white p-3 rounded-full hover:bg-slate-600 w-10 h-10 hover:w-32 hover:flex hover:justify-center transition-all duration-400 ease-in-out" onClick={logout}><CgLogOut /></button>
                  </div>
                </div>
              ) : (
                <button
                  onClick={() => setIsOpen(true)}
                  className="ml-3 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-slate-400 hover:bg-slate-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  aria-label="Login"
                >
                  Login
                </button>
              )}
            </div>
            <div className="-mr-2 flex items-center sm:hidden">
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                aria-expanded={isMenuOpen}
                aria-label="Main menu"
              >
                <span className="sr-only">Open main menu</span>
                {isMenuOpen ? (
                  <svg
                    className="block h-6 w-6" w-32
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                ) : (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>
        <div className={`sm:hidden ${isMenuOpen ? "block" : "hidden"}`}>
          <div className="pt-2 pb-3 space-y-1">
            {isLoggedIn ? (
              <div className="flex items-center px-4">
                <div className="flex-shrink-0">
                  <img
                    className="h-10 w-10 rounded-full"
                    src={avatarSrc}
                    alt="User avatar"
                  />
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium text-gray-800">
                    Hi <span className="text-red-400 font-bold">{fullName}</span></div>
                </div>
                <button className="ml-3 font-bold bg-slate-400 text-white p-3 rounded-full hover:bg-slate-600 hover:text-white" onClick={logout}><CgLogOut /></button>

              </div>
            ) : (
              <button
                onClick={() => setIsOpen(true)}
                className="block w-full text-left px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                aria-label="Login"
              >
                Login
              </button>
            )}
          </div>
        </div>
      </nav>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            className="fixed z-10 inset-0 overflow-y-hidden"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                aria-hidden="true"
              ></div>
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <motion.div
                ref={modalRef}
                initial={{scale: 0.9, opacity: 0}}
                animate={{scale: 1, opacity: 1}}
                exit={{scale: 0.9, opacity: 0}}
                transition={{type: "spring", damping: 20, stiffness: 300}}
                className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
              >
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-indigo-100">
                    <FaUser className="h-6 w-6 text-blue-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <h3
                      className="text-lg leading-6 font-medium text-gray-900"
                      id="modal-title"
                    >
                      Login
                    </h3>
                    <div className="mt-2">
                      <form onSubmit={handleSubmit}>
                        <div>
                          <label
                            htmlFor="fullName"
                            className="block text-sm font-medium text-gray-700 text-left"
                          >
                            Full Name
                          </label>
                          <input
                            type="text"
                            name="fullName"
                            id="fullName"
                            value={fullName}
                            onChange={(e) => {
                              setFullName(e.target.value);
                              if (e.target.value.trim() !== "") {
                                setError("");
                              }
                            }}
                            className={`mt-1 w-full sm:text-sm border-2 border-slate-400 outline-none py-2 px-2 ${error
                              ? "border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500"
                              : ""
                              }`}
                            placeholder="Enter your full name"
                            aria-invalid={error ? "true" : "false"}
                            aria-describedby={error ? "fullName-error" : undefined}
                          />
                          {error && (
                            <p
                              className="mt-2 text-sm text-red-600"
                              id="fullName-error"
                            >
                              {error}
                            </p>
                          )}
                        </div>
                        <div className="mt-5 sm:mt-6">
                          <button
                            type="submit"
                            className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default Navbar;
