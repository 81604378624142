export const vocabularies = [
  "divergent",
  "descend",
  "intermediary",
  "get a pass",
  "transgression",
  "fall in line with",
  "dry",
  "aesthetic",
  "epoch",
  "metallurgy",
  "fanciful",
  "quasi-historic",
  "funerary", //--
  "call something into question",
  "trump",
  "cremation",
  "stumble across",
  "eschew",
  "archaic",
  "neoclassical",
  "anthropomorphized",
  "first and foremost",
  "milieu",
  "of his ilk"
]

export const illustrations = [
  "https://o.quizlet.com/BpEHR2jq2mLZF7IGp3LJ1A.png",
  "https://o.quizlet.com/vDu-lTBmoJGlCCz2yJBXww.jpg",
  "https://o.quizlet.com/MF7fwXyh1nK8lqI9RBS8ug.jpg",
  "https://o.quizlet.com/7ifQVbiZIx.7LWGlkeAp-Q.jpg",
  "https://o.quizlet.com/esNvF2iHb939Lver8bQd8w.jpg",
  "https://o.quizlet.com/i/qEQOOhwMjYiS71yqv1RARQ.jpg",
  "https://o.quizlet.com/I80Oq3AVEuAhA2Wtw2au0w.jpg",
  "https://o.quizlet.com/i/uWLgLdK4jZKzOnMxxzh3Kw.jpg",
  "https://o.quizlet.com/xUEMUnuLUt0qDid3ted-hw.jpg",
  "https://o.quizlet.com/i/TGJPNBOlAaktwlw_DJGq4Q.jpg",
  "https://o.quizlet.com/dm92ZZ5J2WslmjnIwsuePg.png",
  "https://o.quizlet.com/zDld08ZIdSY6oq-QUejPEw.jpg",
  "https://o.quizlet.com/qKBn5RgfAO1YePdPJR70DA.jpg",
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ82yxKGnhBpeKVLlQ-tAt0CcgNAOCYrquycA&s",
  "https://o.quizlet.com/1pDFYzj9hiUz.OzsRSoF8Q.jpg",
  "https://o.quizlet.com/Gnql276FDBAkvlghb244vA.jpg",
  "https://o.quizlet.com/8z.-xcKzz7RHM-kiJraAsQ.jpg",
  "https://o.quizlet.com/79Mutm9OvtltBnNILXjU2w.jpg",
  "https://o.quizlet.com/i/tqPFZIvnmKr-oOhD40xZrg.jpg",
  "https://o.quizlet.com/HV8P-isWqubbprt86sT80w.jpg",
  "https://rockwellcenter.org/wp-content/uploads/2014/04/fig-4.jpg",
  "https://o.quizlet.com/sdsw5RMNZXOWm.81QHw6yg.jpg",
  "https://o.quizlet.com/5SW0Eyp00UgklQRR7Ul-qg.jpg",
  "https://static.wikia.nocookie.net/muppet/images/5/5a/IkeIlk02.jpg"
]

export const meanings = [
  "Developing or moving in different directions; becoming less similar",
  "To come or go down from a higher to a lower level",
  "A person or an organization that helps other people or organizations to make an agreement by being a means of communication between them",
  "Be excused from",
  "Sin or wrongdoing",
  "Follow or proceed in agreement with a system or set of rules",
  "Boring, unemotional",
  "Connected with beauty and art and the understanding of beautiful things",
  "A period of time in history",
  "The scientific study of metals and their uses",
  "Imagined",
  "From the past",
  "Connected with the dead or remembering the dead",
  "To doubt",
  "Be stronger than something else",
  "The practice of burning the dead body",
  "Find something by accident",
  "Deliberately avoid",
  "Very old, no longer important",
  "In the style of ancient Greece or Rome, but produced long afterwards",
  "Given human characteristics, even though it is not human",
  "Most importantly",
  "Social or cultural environment",
  "Similar to him"
]

export const meaningVis = [
  "Khác biệt",
  "Hạ xuống",
  "Trung gian",
  "Nhận được giấy phép",
  "Hành vi vi phạm",
  "Tuân theo",
  "Khô",
  "Thẩm mỹ",
  "Kỷ nguyên",
  "Kim loại học",
  "Huyền bí, tưởng tượng",
  "Từ lịch sử",
  "Liên quan đến tang lễ",
  "Đặt điều gì đó vào nghi vấn",
  "Đánh bại, vượt qua",
  "Hỏa táng",
  "Tình cờ phát hiện",
  "Tránh xa",
  "Cổ xưa, lỗi thời",
  "Tân cổ điển",
  "Nhân hóa",
  "Trước hết và quan trọng nhất",
  "Môi trường, bối cảnh",
  "Cùng loại với anh ấy"
];

export const examples = [
  "In the heated debate, the participants presented __________ views on the best approach to solving the complex problem.",
  "As the hikers reached the summit, they prepared to __________ the steep mountain trail, taking in breathtaking views along the way.",
  "The broker acted as an __________ between the buyer and the seller, facilitating smooth communication and negotiation throughout the real estate transaction.",
  "To attend the exclusive event, you'll need to __________ from the organizers.",
  "The professor emphasized the consequences of academic __________, warning students about the importance of maintaining integrity in their work.",
  "The new company policy requires all employees to __________ with the revised code of conduct to ensure a harmonious and ethical work environment.",
  "The lecture on the historical timeline of accounting was so __________ that even the most dedicated students struggled to stay awake.",
  "The art gallery featured an eclectic mix of paintings, sculptures, and installations, each contributing to a unique and captivating __________ experience.",
  "The discovery of electricity marked an __________ in human history, transforming the way people lived, worked, and communicated.",
  "The study of __________ explores the properties and production of metals, revealing the intricate processes involved in extracting, refining, and manipulating these essential materials.",
  "Her novel was a __________ tale of magical realms and mythical creatures, capturing the imagination of readers with its whimsical and enchanting narrative.",
  "The town's preservation committee decided to restore the old mansion, considering it a __________ landmark due to its unique architectural features and ties to the region's early development.",
  "The archaeologists discovered an ancient __________ site, complete with elaborate burial rituals and well-preserved artifacts that provided valuable insights into the culture of that time.",
  "The new evidence presented during the trial began to __________ the reliability of the eyewitness testimony, raising doubts about the accused person's guilt.",
  "In the game of cards, players eagerly awaited the final round to see which suit would __________ the others and determine the winner.",
  "After her passing, she expressed in her will a preference for __________ rather than a traditional burial, leading her family to honor her wishes.",
  "While hiking in the remote forest, we were fortunate to __________ a hidden waterfall, a breathtaking and unexpected discovery.",
  "In an effort to lead a healthier lifestyle, she decided to __________ processed foods and focus on a diet rich in fresh, whole ingredients.",
  "Despite living in a technologically advanced era, the small village maintained its __________ traditions, preserving a unique cultural heritage.",
  "The new government building showcased a __________ architectural style, blending modern functionality with timeless design elements inspired by classical Greek and Roman structures.",
  "In the children's book, the author skillfully __________ the animals, giving them human-like qualities and personalities that captivated young readers.",
  "First and foremost, prioritizing safety is paramount in any construction project to ensure the well-being of workers and the integrity of the structures being built.",
  "In the vibrant artistic __________ of the city, creative minds from diverse backgrounds converged, fostering a rich and dynamic cultural exchange.",
  "John, a charismatic leader of his __________, inspired his team with unparalleled dedication and innovation."
]

export const exs = [
  "In the story, the two friends had ____________ paths, one went up the hill and the other went down to the forest.", // divergent (9 ký tự)
  "The bird will _________ from the tree to the ground to find food.", // descend (7 ký tự)
  "The superhero acted as an ________________, helping the two friends talk and make up after the fight.", // intermediary (12 ký tự)
  "I will ___ _ ____ from cleaning my room today because mom said I can play instead!", // get a pass (9 ký tự bao gồm khoảng cách)
  "If you draw on the wall, it is a _____________, and you might get in trouble!", // transgression (12 ký tự)
  "Everyone needs to ____ __ ____ ____ ____ the rules at school, like lining up quietly.", // fall in line with (16 ký tự bao gồm khoảng cách)
  "The story was so ___ that everyone in the class yawned at the same time!", // dry (3 ký tự)
  "The princess in the painting was so __________ that everyone wanted to look at her all day!", // aesthetic (9 ký tự)
  "Dinosaurs lived in a different _____, a very, very long time ago.", // epoch (5 ký tự)
  "The blacksmith knew all about _____________ and how to make shiny metal swords!", // metallurgy (10 ký tự)
  "The dragon in the story was very ____________, with rainbow wings and a magical tail.", // fanciful (8 ký tự)
  "The old house looked _____________ because it reminded everyone of an ancient castle.", // quasi-historic (14 ký tự)
  "They found an old _________ box filled with treasures in the sandpit.", // funerary (8 ký tự)
  "My friend’s story was so strange that it made me ____ __________ ____ _________ whether it was true or not!", // call something into question (28 ký tự bao gồm khoảng cách)
  "In the game of cards, players waited to see which suit would _____ all the others!", // trump (5 ký tự)
  "After someone passes away, some people choose _________ to remember them.", // cremation (9 ký tự)
  "We were playing outside when we __________ ________ a cute kitten hiding in the bushes!", // stumble across (14 ký tự bao gồm khoảng cách)
  "The kid decided to _______ chocolate so that their teeth wouldn’t get cavities.", // eschew (6 ký tự)
  "Grandpa’s old radio is so _______, it doesn’t work anymore, but it looks cool!", // archaic (7 ký tự)
  "The big white building in the park looks like it’s from ancient Greece with its ______________ design.", // neoclassical (12 ký tự)
  "In the cartoon, all the animals were ______________________ and talked like people!", // anthropomorphized (17 ký tự)
  "First and _________ ____ _________, you need to brush your teeth before bed every night.", // first and foremost (18 ký tự bao gồm khoảng cách)
  "The classroom was a happy _______ full of laughter and colorful drawings on the walls.", // milieu (6 ký tự)
  "His friends were all of his ____ ____ ___, sharing the same love for toy robots." // of his ilk (10 ký tự bao gồm khoảng cách)
];

export const exs_vi = [
  "Trong câu chuyện, hai người bạn có những con đường __________, một người lên đồi và một người đi xuống rừng.",
  "Con chim sẽ __________ từ trên cây xuống mặt đất để tìm thức ăn.",
  "Siêu anh hùng là một người __________, giúp hai người bạn nói chuyện và làm hòa sau khi cãi nhau.",
  "Hôm nay, mình sẽ __________ việc dọn phòng vì mẹ nói mình có thể chơi thay vào đó!",
  "Nếu bạn vẽ lên tường, đó là một __________ và bạn có thể gặp rắc rối!",
  "Mọi người cần __________ với các quy tắc ở trường, như xếp hàng im lặng.",
  "Câu chuyện __________ đến mức cả lớp đều ngáp cùng lúc!",
  "Công chúa trong bức tranh rất __________ đến mức ai cũng muốn ngắm cô ấy cả ngày!",
  "Khủng long sống ở một __________ khác, từ rất lâu rồi.",
  "Người thợ rèn biết tất cả về __________ và cách làm những thanh kiếm kim loại sáng bóng!",
  "Con rồng trong câu chuyện rất __________, với đôi cánh cầu vồng và cái đuôi kỳ diệu.",
  "Ngôi nhà cũ trông __________ vì nó khiến mọi người nhớ đến một lâu đài cổ xưa.",
  "Họ đã tìm thấy một chiếc hộp __________ cổ đầy kho báu trong hố cát.",
  "Câu chuyện của bạn mình rất lạ đến mức mình phải __________ liệu nó có thật hay không!",
  "Trong trò chơi bài, người chơi chờ đợi xem lá bài nào sẽ __________ tất cả các lá khác!",
  "Sau khi ai đó qua đời, một số người chọn __________ để tưởng nhớ họ.",
  "Chúng mình đang chơi ngoài trời thì __________ gặp một chú mèo con dễ thương trốn trong bụi cây!",
  "Đứa trẻ quyết định __________ sô cô la để răng không bị sâu.",
  "Chiếc radio cũ của ông nội rất __________, nó không còn hoạt động nữa, nhưng trông vẫn ngầu!",
  "Tòa nhà trắng lớn trong công viên trông giống như từ Hy Lạp cổ đại với thiết kế __________ của nó.",
  "Trong phim hoạt hình, tất cả các con vật đều __________ và nói chuyện như con người!",
  "Trước tiên và __________, bạn cần đánh răng trước khi đi ngủ mỗi đêm.",
  "Lớp học là một __________ vui vẻ, đầy tiếng cười và những bức tranh đầy màu sắc trên tường.",
  "Bạn của cậu ấy đều là những người __________, cùng thích robot đồ chơi như nhau."
];

